<template>
  <div class="">
    <div class="card-table" :class="mode ? 'card__tableday' : 'card__tablenight'">
      <div class="card-table-body ifon-add-title-block">
        <el-row :gutter="20">
          <el-col :span="12">
            <div class="caz-blocks-sarcho-title">
              <div :class="mode ? 'content__titleday' : 'content__titlenight'" class="content-title d-flex align-center mr-2"> {{ $t("message.trackEmail") }} </div>
              <div class="block-sarche">
                <div class="header__search">
                  
                   <crm-input
                        :size="'small'"
                        :class="mode ? 'input__day' : 'input__night'"
                        :className="'w100'"
                        v-model="filterForm.search"
                        :icon="'el-icon-search'"
                    ></crm-input>
                </div>
              </div>
            </div>
          </el-col>

          <el-col :span="12" class="flex-style text-right">

             <crm-create-and-column-settings
                  :permission="$options.name"
                  :class="mode ? 'button__settingsday' : 'button__settingsnight'"
                  @c-create="drawerCreate = true"
                  :columns="columns"
                  @c-change="updateColumn"
              >
              </crm-create-and-column-settings>
          </el-col>
        </el-row>
      </div>
      <!-- end ifon-add-title-block -->

      <div class="card-table-header table-crm-smart custom__scroll">
        <table 
          class="table-my-code table-bordered"
          :class="mode ? 'table__myday' : 'table__mynight'"
          v-loading="loadingData"
        >
          <thead>
            <tr>
                <th class="w50p" v-if="columns.id.show">
                    {{ columns.id.title }}
                </th>

                <th v-if="columns.client_id.show">
                    {{ columns.client_id.title }}
                </th>

                <th v-if="columns.email.show">
                    {{ columns.email.title }}
                </th>

                <th v-if="columns.track_code.show">
                    {{ columns.track_code.title }}
                </th>

                <th v-if="columns.user_id.show">
                    {{ columns.user_id.title }}
                </th>

                <th v-if="columns.deal_user.show">
                    {{ columns.deal_user.title }}
                </th>

                <th v-if="columns.deal_id.show">
                    {{ columns.deal_id.title }}
                </th>

                <th v-if="columns.comment.show">
                    {{ columns.comment.title }}
                </th>

                <th v-if="columns.send_date.show">
                    {{ columns.send_date.title }}
                </th>

                <th v-if="columns.created_at.show">
                    {{ columns.created_at.title }}
                </th>

                <th v-if="columns.updated_at.show">
                    {{ columns.updated_at.title }}
                </th>
            </tr>

            <tr class="filter_sorche">
              <th v-if="columns.id.show">
                  <el-input
                      clearable
                      size="mini"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.id"
                      :placeholder="columns.id.title"
                      class="id_input"
                  ></el-input>
              </th>

              <th v-if="columns.client_id.show">
                <select-client
                    :size="'medium'"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    :placeholder="columns.client_id.title"
                    v-model="filterForm.client_id"
                >
                </select-client>
              </th>

              <th v-if="columns.email.show">
                  <crm-input
                      :placeholder="columns.email.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.email"
                  ></crm-input>
              </th>

              <th v-if="columns.track_code.show">
                  <crm-input
                      :placeholder="columns.track_code.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.track_code"
                  ></crm-input>
              </th>

              <th v-if="columns.user_id.show">
                <select-user
                    :size="'mini'"
                    :placeholder="$t('message.user')"
                    :id="filterForm.user_id"
                    :class="mode ? 'filter__day' : 'filter__night'"
                    v-model="filterForm.user_id"
                >
                </select-user>
              </th>

              <th v-if="columns.deal_user.show">
              </th>

              <th v-if="columns.deal_id.show">
                  <crm-input
                      :placeholder="columns.deal_id.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.deal_id"
                  ></crm-input>
              </th>

              <th v-if="columns.comment.show">
              </th>

              <th v-if="columns.send_date.show">
                  <crm-date-picker
                      :placeholder="columns.send_date.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.send_date"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.created_at.show">
                  <crm-date-picker
                      :placeholder="columns.created_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.created_at"
                  ></crm-date-picker>
              </th>

              <th v-if="columns.updated_at.show">
                  <crm-date-picker
                      :placeholder="columns.updated_at.title"
                      :class="mode ? 'filter__day' : 'filter__night'"
                      v-model="filterForm.updated_at"
                  ></crm-date-picker>
              </th>

          </tr>
          </thead>

          <transition-group name="flip-list" tag="tbody">
            <tr v-for="track_email in list" :key="track_email.id" class="cursor-pointer">

                <td v-if="columns.id.show">
                    {{ track_email.id }}
                </td>

                <td v-if="columns.client_id.show">
                    <span class="font-bold d-block">
                       ID: {{ track_email.client ? track_email.client.custom_id : ''}}
                    </span>
                    {{ track_email.client ? track_email.client.name : ''}}
                </td>

                <td v-if="columns.email.show">
                    {{ track_email.email }}
                </td>

                <td v-if="columns.track_code.show">
                    {{ track_email.track_code }}
                </td>

                <td v-if="columns.user_id.show">
                    {{ track_email.user ? track_email.user.name : '' }}
                </td>

                <td v-if="columns.deal_user.show">
                    {{ track_email.deal_user ? track_email.deal_user.name : '' }}
                </td>

                <td v-if="columns.deal_id.show">
                    {{ track_email.deal_id }}
                </td>

                <td v-if="columns.comment.show">
                    {{ track_email.comment }}
                </td>

                <td v-if="columns.send_date.show">
                    {{ track_email.send_date }}
                </td>
               
                <td v-if="columns.created_at.show">
                    {{ track_email.created_at }}
                </td>

                <td v-if="columns.updated_at.show">
                    {{ track_email.updated_at }}
                </td>
            </tr>
          </transition-group>
        </table>
        <div class="my___pagination">
          <crm-pagination
            @c-change="updatePagination"
            :class="mode ? 'pagination__day' : 'pagination__night'"
            :pagination="pagination"
          ></crm-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import list from "@/utils/mixins/list";
import { mapGetters, mapActions } from "vuex";
import selectUser from "@/components/inventory/select-users";
import selectClient from "@/components/inventory/select-client";

export default {
    name: "trackEmail",
    mixins: [list],
    components: {
        selectUser,
        selectClient,
    },

    data() {
        return {

        };
    },

    computed: {
        ...mapGetters({
            list: "trackEmail/list",
            columns: "trackEmail/columns",
            pagination: "trackEmail/pagination",            
            filter: "trackEmail/filter",
            sort: "trackEmail/sort",
            mode: "MODE"
        }),
        actions: function () {
            return ["edit", "delete"];
        },
    },
    methods: {
        ...mapActions({
            updateList: "trackEmail/index",
            setPagination: "trackEmail/setPagination",
            updateSort: "trackEmail/updateSort",
            updateFilter: "trackEmail/updateFilter",
            updateColumn: "trackEmail/updateColumn",
            updatePagination: "trackEmail/updatePagination",
            show: "trackEmail/show",
            empty: "trackEmail/empty",
            delete: "trackEmail/destroy",
            refreshData: "trackEmail/refreshData",
        }),
    
    },
    beforeRouteLeave (to, from, next) {
        this.$store.commit('trackEmail/EMPTY_LIST');
        next()
    },
};
</script>

